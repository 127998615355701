import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Col, Row, Space, Typography } from 'antd';
import React from 'react';
const { Title, Paragraph } = Typography;
const darkTheme = createTheme({ palette: { mode: 'dark' } });



function Hero() {
  return (
    <div className="hero pt-0 pb-0 pt-sm-5 pb-sm-5" style={{  
        // backgroundImage: 'radial-gradient( circle farthest-corner at 17.1% 22.8%,  rgba(226,24,24,1) 0%, rgba(160,6,6,1) 90% )',
      padding: '80px 0',
      color: 'white'
    }}>
        <div id="stripes">
  
      </div>
      <div className="container">
      <Row justify="center"  align="center">
        <Col xs={24} md={14} style={{ textAlign: 'left',display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-start' }}>
        <ThemeProvider theme={darkTheme}>
          {/* <Paper elevation={3} style={{ padding: '1rem', backgroundColor: 'transparent' }}> */}
            <Typography>
              <Title className='d-none' style={{ color: 'white', fontSize: '3rem', marginBottom: '5px',  fontFamily: 'Expletus Sans' }}>
                <span className="font-nunito font-800" style={{fontSize:1.5+'em'}}>Menu</span><span style={{fontVariant:'small-caps'}} className='font-nunito font-300'>portal</span>
              </Title>
              <Title level={2} className='text-center text-md-left hero-main-title' style={{  paddingRight:'0px',  color: '#313131', fontWeight: '800', marginBottom: '10px', marginTop: '0px', fontFamily:'DM Sans',textTransform:'capitalize', }}> 
              Professional API Solutions for Restaurant & Menu Data Sourcing
              {/* The API to Optimize Restaurant & Menu Data Sourcing. */}

              {/* API for Effortless Restaurant Data Sourcing */}
              {/* A Robust API for sourcing restaurant & menu data.
               */}
{/* A developer-focused API providing restaurant & menu data */}
                {/* <span style={{fontSize: '1rem',  fontFamily:'Hind'}}><span className='exag'>600,000<sup> +</sup></span> Restaurants <br/><span className='exag'>18,000,000<sup> +</sup></span> Menu Items</span> */}
              </Title>
              {/* <Paragraph style={{ fontSize: '1.2rem', maxWidth: '600px', marginLeft: 'auto', marginBottom: '40px' }}>
                Whether you're looking for your favorite dish or want to explore new restaurants, 
                MenuPortal is your gateway to an extensive library of menus from all across the U.S.9
              </Paragraph> */}
            </Typography>
          {/* </Paper> */}
          <div className='d-flex flex-row flex-nowrap justify-content-between gap-3 w-80'>
          <Button variant="outlined" color="dark" size='large' style={{ backgroundColor: '#9d090c', marginTop: '1rem', padding: '1.5rem 2rem', fontFamily:'Hind',fontWeight:700, fontSize: '1.2rem' ,textTransform:"uppercase", letterSpacing: '0.1em'}} href="/docs" defaultHoverBg="black" defaultColor="black" linkColor="black" className='hero-button d-none d-md-inline-flex'>
            Documentation
            </Button>
          <Button variant="outlined" color="primary" size='large' style={{ color: '#9d090c', borderColor: '#9d090c',backgroundColor:'transparent', border: '2px solid #9d090c', marginTop: '1rem', padding: '1.5rem 2rem', fontFamily:'Hind',fontWeight:700, fontSize: '1.2rem' ,textTransform:"uppercase", letterSpacing: '0.1em'}} href="/register" defaultHoverBg="black" defaultColor="black" linkColor="black" className='hero-button-two d-none d-md-inline-flex'>Get Started</Button>
          </div>
          </ThemeProvider>
        </Col>
        <Col xs={24} md={2} className='d-none d-sm-block'></Col>
        <Col xs={8} md={6} style={{ textAlign: 'center' }}>
          <Space direction="vertical" size="large">
            <img src="np5.png" alt="" style={{ width: '100%' }} /> 
            {/* <Button type="primary" size="large" style={{ width: '200px' }}> */}
              {/* <Link to="/docs">View Documentation</Link> */}
            {/* </Button> */}
            {/* <Button size="large" style={{ width: '200px' }}>
              <Link to="/previewapi">Try API Preview</Link>
            </Button> */}
          </Space>
          
        </Col>
        <Col xs={24} md={2} className='d-block d-md-none'>
        <Button block variant="outlined" color="dark" size='large' style={{ backgroundColor: '#9d090c', marginTop: '1rem', padding: '1.5rem 2rem', fontFamily:'Hind',fontWeight:700, fontSize: '1.2rem' ,textTransform:"uppercase", letterSpacing: '0.1em'}} href="/docs" defaultHoverBg="black" defaultColor="black" linkColor="black" className='hero-button'>
            View Documentation
          </Button></Col>
      </Row>
      </div>
    </div>
  );
}

export default Hero;
