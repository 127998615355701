  import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import ApiPreviewPage from './components/Updated/ApiPreviewPage';
import Docs from './components/Updated/Docs';
import NewHomePage from './components/Updated/NewHomePage';
import NotFound from './components/Updated/NotFound';
import { useAuth } from './contexts/AuthContext';
import AuthPage from './pages/auth';
import Dashboard from './pages/Dashboard';

const App = () => {
  const { loading } = useAuth();
  const underMaintenance = true;
  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }
  if(underMaintenance) {
    return <div className="under-maintenance"></div>;
  }

  return (
    <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
      <Routes>
        <Route path="/" element={<NewHomePage />} />
        <Route path="/preview-api" element={<ApiPreviewPage />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/login" element={<AuthPage />} />
        <Route path="/register" element={<AuthPage />} />
        
        {/* Protected routes */}
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;
