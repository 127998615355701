import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import config from '../config';
// import { config } from 'dotenv'; 
// import dotenv from 'dotenv';
// dotenv.config();
// config();


// const REACT_APP_API_URL = import.meta.env.VITE_REACT_APP_ENV === 'dev' ? import.meta.env.VITE_API_URL_DEV : import.meta.env.REACT_APP_API_URL_PROD;
const REACT_APP_API_URL = config.baseUrl;

const AuthContext = createContext({});


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [logs, setLogs] = useState([]);   
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkAuth(); 
  }, []);

  const checkAuth = async () => {
    console.log('Checking auth');
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios.get( REACT_APP_API_URL + '/auth/verify', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log(response.data);
        console.log(response.data.accessLogs);
        console.log(response.data.user);
        setUser(response.data.user);
        setLogs(response.data.accessLogs);
      }
    } catch (err) {
      setError(err.message);
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  const register = async (credentials) => {
    console.log('Registering');
    try {
      const response = await axios.post(REACT_APP_API_URL + '/auth/register', credentials);
      localStorage.setItem('token', response.data.token);
      setUser(response.data.user);
      
      return response.data;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };  

  const login = async (credentials) => {
    console.log('Logging in');
    try {
      const response = await axios.post(REACT_APP_API_URL + '/auth/login', credentials);
      localStorage.setItem('token', response.data.token);
      setUser(response.data.user);
      setLogs(response.data.logs);
      return response.data;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const logout = () => {
    console.log('Logging out');
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, login, logout, register, logs }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 